import styled from 'styled-components';
import { theme } from 'styled-tools';
import { GUIDE_FORM_STATUS } from '../../constants';

const SubscribeButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${theme('dims.subscribeButton.height')};
    color: ${theme('colors.subscribeButton.color')};
    font-weight: bold;
    font-size: ${theme('fonts.subscribeButton.size')};
    
    background: ${(props) => {
        switch (props.guideFormStatus) {
            case GUIDE_FORM_STATUS.ERROR_OCCURRED: {
                return theme('colors.error');
            }
            case GUIDE_FORM_STATUS.SUCCESS: {
                return theme('colors.success');
            }
            default:
                return theme('colors.subscribeButton.backgroundColor');
        }
    }};

    border: none;
    border-radius: ${theme('dims.subscribeButton.borderRadius')};
    margin-top: ${theme('dims.subscribeButton.marginVertical')};
    margin-bottom: ${theme('dims.subscribeButton.marginVertical')};
    
    :focus {
        outline: none;
    }

    cursor: pointer;
`;

export default SubscribeButton;
