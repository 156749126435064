import axios from 'axios';

const BACKEND_API_ENDPOINT = process.env.REACT_APP_BACKEND_API;
const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN;

export const getGuideRequest = async (email, phone, consents) => axios.post(
    `${BACKEND_API_ENDPOINT}/guideDownloadForm`, {
        access_token: ACCESS_TOKEN,
        email,
        phone,
        consents
    },
    {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        }
    }
)
    .then((response) => response.data);
