import styled from 'styled-components';
import { theme } from 'styled-tools';

const GuideFormContainer = styled.div`
    width: ${theme('dims.guideFormContainer.width')};
    max-width: ${theme('dims.guideFormContainer.width')};
    padding: ${theme('dims.guideFormContainer.padding')};
    align-items: center;
    flex-direction: column;
`;

export default GuideFormContainer;
