import React, {
    useEffect, useState, useRef
} from 'react';
import ReactDOM from 'react-dom';
import * as PropTypes from 'prop-types';
import { Formik } from 'formik';
import { BeatLoader } from 'react-spinners';
import {
    prop, set, lensProp, reduce, includes, replace, toUpper, identity, isNil
} from 'ramda';
import TextInput from '../../components/TextInput';
import Box from '../../components/Box';
import Title from '../../components/Title';
import {
    GUIDE_FORM_CHECKBOXES,
    GUIDE_FORM_STATUS,
    GUIDE_FORM_TEXT_INPUTS,
    FIELD_VALIDATION_STATUSES,
    DATA_PROCESSING_INFO_TEXT,
    DATA_PROCESSING_INFO, CLOSE_TEXT, INITIAL_CONSENT_TEXT, MISSING_REQUIRED_CONSENTS
} from './constants';
import ErrorMessage from '../../components/ErrorMessage';
import { CONSENT_REQUIRED } from './messages';
import { GuideFormContainer, PrivacyLink, SubscribeButton } from './components';
import CheckboxInput from '../../components/CheckboxInput';
import TextInputLabel from '../../components/TextInputLabel';
import Spacer from '../../components/Spacer';
import CheckboxLabel from '../../components/CheckboxLabel';
import ModalContent from '../../components/Modal/ModalContent';
import CloseIcon from '../../components/CloseIcon';

import { getGuideRequest } from '../../api';

import BackgroundOverlay from '../../components/BackgroundOverlay';
import Modal from '../../components/Modal';

import './styles.css';
import { TextBox } from '../../components/TextBox';
import { LinkSpan } from '../../components/LinkSpan';
import { StyledForm } from '../../components/StyledForm';
import { PostConsentsInfo } from './components/PostConsentsInfo';
import AllConsentsWrapper from './components/AllConsentsWrapper';
import ExpandCheckboxes from './components/ExpandCheckboxes';

const GuideForm = ({ isVisible, closeModal }) => {
    const [guideFormStatus, setGuideFormStatus] = useState(GUIDE_FORM_STATUS.FILLING_INFORMATION);
    const [shouldFireGetGuideRequest, setShouldFireGetGuideRequest] = useState(false);
    const [isDataProcessingInfoVisible, changeDataProcessingInfoVisibility] = useState(false);
    const [areConsentsExpanded, setConsentsExpanded] = useState(false);
    const dataProcessingTextRef = useRef(0);

    const [values, setValues] = useState({});

    const scrollToRef = (ref) => ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

    useEffect(() => {
        const subscribeForm = async () => {
            setShouldFireGetGuideRequest(false);

            try {
                setGuideFormStatus(GUIDE_FORM_STATUS.AWAITING_RESULT_FROM_SERVER);
                const { email, phone, consents } = values;

                await getGuideRequest(email, phone, consents);

                setGuideFormStatus(GUIDE_FORM_STATUS.SUCCESS);
            }
            catch (error) {
                console.error(error);

                setGuideFormStatus(GUIDE_FORM_STATUS.ERROR_OCCURRED);
            }
        };

        if (shouldFireGetGuideRequest) {
            subscribeForm();
        }
    }, [shouldFireGetGuideRequest]);

    useEffect(() => {
        if (isDataProcessingInfoVisible && !isNil(dataProcessingTextRef.current)) {
            scrollToRef(dataProcessingTextRef);
        }
    }, [isDataProcessingInfoVisible]);

    const formikRef = useRef();

    const specificConsents = Object.values(GUIDE_FORM_CHECKBOXES);

    const dataProcessingText = DATA_PROCESSING_INFO.map((text) => (
        <TextBox
            text={text}
        />
    ));

    const capitalize = replace(/^./, toUpper);
    const capitalizedCloseText = capitalize(CLOSE_TEXT);

    if (!isVisible) {
        return <></>;
    }

    return ReactDOM.createPortal(
        <BackgroundOverlay>
            <Modal>
                <CloseIcon onClick={closeModal} />
                <ModalContent>
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            email: '',
                            phone: '',
                            consents: []
                        }}
                        onSubmit={({
                            email, phone, consents
                        }) => {
                            setValues({ email, phone, consents });
                            setShouldFireGetGuideRequest(true);
                        }}
                        validate={(values) => {
                            const errors = reduce((errors, { name, validate }) => {
                                const { status, error } = validate(prop(name, values));

                                if (status === FIELD_VALIDATION_STATUSES.ERROR) {
                                // eslint-disable-next-line no-param-reassign
                                    errors = set(lensProp(name), error, errors);
                                }
                                return errors;
                            }, {}, GUIDE_FORM_TEXT_INPUTS);

                            const { phone, consents } = values;

                            if (!includes(GUIDE_FORM_CHECKBOXES.EMAIL.id, consents)) {
                                errors.consentEmail = CONSENT_REQUIRED;
                            }

                            if (phone !== '' && !includes(GUIDE_FORM_CHECKBOXES.SMS.id, consents)) {
                                errors.consentSMS = CONSENT_REQUIRED;
                            }

                            return errors;
                        }}
                    >
                        {({
                            values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue
                        }) => (
                            <>
                                <GuideFormContainer>
                                    <StyledForm onSubmit={handleSubmit}>
                                        <Box column>
                                            <Box column>
                                                <Title>
                                                    7 powodów
                                                    <br />
                                                    dlaczego warto nosić
                                                    <br />
                                                    soczewki kontaktowe.
                                                </Title>
                                            </Box>
                                            <Spacer />
                                            <Box>
                                                <Title>
                                                    Pobierz poradnik
                                                </Title>
                                            </Box>
                                            {
                                                GUIDE_FORM_TEXT_INPUTS.map((textInput) => (
                                                    <Box column marginTop="10px">
                                                        <TextInputLabel>
                                                            {textInput.label}
                                                        </TextInputLabel>
                                                        <TextInput
                                                            type="text"
                                                            name={textInput.name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={prop(textInput.name, values)}
                                                        />
                                                        <ErrorMessage
                                                            msg={prop(textInput.name, errors)}
                                                            isVisible={prop(textInput.name, errors) && prop(textInput.name, touched)}
                                                        />
                                                    </Box>
                                                ))
                                            }
                                            <SubscribeButton
                                                type="submit"
                                                guideFormStatus={guideFormStatus}
                                                disabled={!([GUIDE_FORM_STATUS.FILLING_INFORMATION, GUIDE_FORM_STATUS.ERROR_OCCURRED].includes(guideFormStatus))}
                                            >
                                                {
                                                    {
                                                        [GUIDE_FORM_STATUS.FILLING_INFORMATION]: 'Wyślij poradnik',
                                                        [GUIDE_FORM_STATUS.AWAITING_RESULT_FROM_SERVER]: <BeatLoader color="#FFF" size={10} />,
                                                        [GUIDE_FORM_STATUS.ERROR_OCCURRED]: 'Wystąpił błąd, spróbuj jeszcze raz',
                                                        [GUIDE_FORM_STATUS.SUCCESS]: 'Poradnik wysłany'
                                                    }[guideFormStatus]
                                                }

                                            </SubscribeButton>
                                            <TextBox text={INITIAL_CONSENT_TEXT} />
                                            <Box marginTop="10px">
                                                <input
                                                    checked={values.consents.length === Object.values(GUIDE_FORM_CHECKBOXES).length}
                                                    type="checkbox"
                                                    onClick={() => {
                                                        if (values.consents.length === Object.values(GUIDE_FORM_CHECKBOXES).length) {
                                                            setFieldValue('consents', []);
                                                        }
                                                        else {
                                                            setFieldValue('consents', Object.values(GUIDE_FORM_CHECKBOXES).map(prop('id')));
                                                        }
                                                    }}
                                                    style={{ marginTop: '2px' }}
                                                    id="all"
                                                />
                                                <AllConsentsWrapper>
                                                    <CheckboxLabel htmlFor="all">
                                                        <div>Akceptuję wszystkie zgody</div>
                                                    </CheckboxLabel>
                                                    <ExpandCheckboxes onClick={() => setConsentsExpanded(!areConsentsExpanded)}>
                                                        {areConsentsExpanded ? '(Zwiń zgody)' : '(Rozwiń zgody)'}
                                                    </ExpandCheckboxes>
                                                    {
                                                        (errors.consentSMS || errors.consentEmail) && touched.consents
                                                && (
                                                    <ErrorMessage>
                                                        { MISSING_REQUIRED_CONSENTS }
                                                    </ErrorMessage>
                                                )
                                                    }
                                                </AllConsentsWrapper>
                                            </Box>
                                            {areConsentsExpanded ? (
                                                <div>
                                                    <Box marginVertical="20px" data-tip="React-tooltip" data-event="click focus">
                                                        <CheckboxLabel>
                                                            (Proszę zaznaczyć kanał(y) komunikacji, do którego(ych) ma zastosowanie Państwa zgoda)
                                                        </CheckboxLabel>
                                                    </Box>

                                                    {
                                                        specificConsents.map((checkbox) => (
                                                            <CheckboxInput checkbox={checkbox} errors={errors} touched={touched} values={values} />
                                                        ))
                                                    }
                                                </div>
                                            ) : null}
                                            <PostConsentsInfo />
                                            <LinkSpan onClick={() => changeDataProcessingInfoVisibility(!isDataProcessingInfoVisible)}>
                                                {DATA_PROCESSING_INFO_TEXT(isDataProcessingInfoVisible)}
                                            </LinkSpan>
                                            {isDataProcessingInfoVisible
                                                ? (
                                                    <Box column>
                                                        { dataProcessingText }
                                                        <PrivacyLink href="https://coopervision.pl/polityka-prywatnosci" target="_blank" rel="noopener noreferrer">
                                                            Polityka Prywatności
                                                        </PrivacyLink>

                                                        <LinkSpan onClick={() => changeDataProcessingInfoVisibility(!isDataProcessingInfoVisible)} ref={dataProcessingTextRef}>
                                                            {capitalizedCloseText}
                                                        </LinkSpan>
                                                    </Box>
                                                )
                                                : null}
                                        </Box>
                                    </StyledForm>
                                </GuideFormContainer>
                            </>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
        </BackgroundOverlay>,
        document.getElementById('react-portal')
    );
};

GuideForm.propTypes = {
    isVisible: PropTypes.bool,
    closeModal: PropTypes.func
};

GuideForm.defaultProps = {
    isVisible: false,
    closeModal: identity
};

export default GuideForm;
