import React from 'react';

import { ReactComponent as CloseIconSvg } from '../../assets/icons/icon_close.svg';

const CloseIcon = ({ size = '20px', ...props }) => (
    <CloseIconSvg
        style={{
            top: 10,
            right: 10,
            position: 'absolute',
            width: size,
            height: size,
            cursor: 'pointer',
            opacity: 0.7
        }}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
    />
);

export default CloseIcon;
