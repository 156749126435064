import styled from 'styled-components';

const ModalContent = styled.div`
  display: flex;
  
  width: 100%;
  padding-bottom: 64px;
  
  padding-left: 40px;
  padding-right: 40px;
  
  overflow-y: scroll;
  
  ::-webkit-scrollbar {
         display: none;
  }
`;

export default ModalContent;
