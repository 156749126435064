import styled from 'styled-components';
import { theme } from 'styled-tools';

const Modal = styled.div`
  position: relative;
  display: flex;
  width: 480px;
  max-width: calc(100% - 40px);
  overflow: hidden;
  max-height: 90vh;
  z-index: 150000;
  background: white;
  border-radius: 1rem;
  
  padding-bottom: 32px;
`;

export default Modal;
