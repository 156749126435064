import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';

const ErrorMessageWrapper = styled.div`
    color: ${theme('colors.error')};
    font-size: ${theme('fonts.checkboxLabel.size')};
    margin-left: ${theme('dims.textMarginLeft')};
`;

const ErrorMessage = ({ msg, isVisible }) => {
    if (!isVisible) {
        return (<></>);
    }
    return (
        <ErrorMessageWrapper>
            {msg}
        </ErrorMessageWrapper>
    );
};

export default ErrorMessage;
