import styled from 'styled-components';

const ClickableImage = styled.img`
    cursor: pointer;
    height: 540px;
    positio: relative;
    object-fit: cover;
    
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
`;

export default ClickableImage;
