import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import App from './containers/App';
import { theme } from './themes';

const rootElement = document.getElementById('cooper-guide-form');

if (rootElement) {
    ReactDOM.render(
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </React.StrictMode>,
        rootElement
    );
}
