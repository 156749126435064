import styled from 'styled-components';
import { theme } from 'styled-tools';

const Spacer = styled.div`
    width: 240px;
    height: 5px;
    border-radius: 3px;
    background-image: linear-gradient(45deg, #5e9feb 0%, #8bd1fd 100%);
    margin: 0 auto;
    margin-bottom: 15px;
`;

export default Spacer;
