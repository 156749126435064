import React, { useState } from 'react';

import guideDownloadImage from '../../assets/imgs/CV_Banner_Poradnik_380x551px.jpg';
import GuideForm from '../GuideForm';

import ClickableImage from './components/ClickableImage';

const App = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <>
            <ClickableImage alt="pobierz poradnik" src={guideDownloadImage} onClick={() => setIsModalVisible(true)} />
            <GuideForm isVisible={isModalVisible} closeModal={() => setIsModalVisible(false)} />
        </>
    );
};

export default App;
