import { always } from 'ramda';

export const GUIDE_FORM_STATUS = {
    FILLING_INFORMATION: 'FILLING_INFORMATION',
    AWAITING_RESULT_FROM_SERVER: 'AWAITING_RESULT_FROM_SERVER',
    ERROR_OCCURRED: 'ERROR_OCCURRED',
    SUCCESS: 'SUCCESS'
};

export const CHECKBOX_TOOLTIP_ID = 'checkbox-tooltip';

export const FIELD_VALIDATION_STATUSES = {
    OK: 'OK',
    ERROR: 'ERROR'
};
const fieldValidationOk = {
    status: FIELD_VALIDATION_STATUSES.OK
};

const fieldValidationError = (error) => ({
    status: FIELD_VALIDATION_STATUSES.ERROR,
    error
});

export const GUIDE_FORM_TEXT_INPUTS = [
    {
        name: 'email',
        label: 'Adres email',
        validate: (email) => {
            if (!email) {
                return fieldValidationError('Pole wymagane');
            }
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
                return fieldValidationError('Niepoprawny adres email');
            }

            return fieldValidationOk;
        }
    },
    {
        name: 'phone',
        label: 'Numer telefonu',
        validate: (phone) => {
            if (phone === '') {
                return fieldValidationOk;
            }
            if (!/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(phone)) {
                return fieldValidationError('Niepoprawny numer telefonu');
            }

            return fieldValidationOk;
        }
    }
];

export const GUIDE_FORM_CHECKBOXES = {
    EMAIL: {
        id: 'email',
        label: 'Poczta elektroniczna (e-mail)',
        requiredTest: always(true),
        errorPropName: 'consentEmail'
    },
    SMS: {
        id: 'sms',
        label: 'SMS/MMS (krótka wiadomość tekstowa lub multimedialna w sieciach telefonii komórkowej)',
        fullText: 'Krótka wiadomość tekstowa lub multimedialna w sieciach telefonii komórkowej.',
        requiredTest: ({ phone }) => phone !== '',
        errorPropName: 'consentSMS'
    },
    CALLS: {
        id: 'calls',
        label: 'Telefoniczne połączenia głosowe.',
        requiredTest: always(false),
        errorPropName: 'consentCalls'
    },
    AFFILIATE: {
        id: 'affiliate',
        label: 'Zgadzam się na otrzymywanie informacji handlowych za pomocą preferowanych kanałów komunikacji również od innych spółek z Grupy CooperVision.',
        fullText: 'Zgadzam się na otrzymywanie informacji handlowych dotyczących programu "Klub Moje Pierwsze Soczewki CooperVision" oraz zakładania, zdejmowania i pielęgnacji soczewek kontaktowych za pomocą preferowanych kanałów komunikacji również od innych spółek z Grupy CooperVision.',
        requiredTest: always(false)
    },
    COOKIES: {
        id: 'cookies',
        label: 'Wyrażam zgodę na otrzymywanie spersonalizowanej komunikacji opartej na profilowaniu i korzystaniu z plików cookie podczas korzystania z produktów, usług i stron internetowych firmy CooperVision',
        fullText: 'Wyrażam zgodę na otrzymywanie spersonalizowanej komunikacji opartej na profilowaniu i korzystaniu z plików cookie podczas korzystania z produktów, usług i stron internetowych firmy CooperVision',
        requiredTest: always(false),
        errorPropName: 'consentCookies'
    }
};

export const POST_CONSENTS_INFO_PRE_LINK = 'Możecie Państwo w dowolnym momencie wycofać wszystkie lub niektóre z udzielonych zgód. Niebędzie to miało wpływu na zgodność z prawem działań opartych na zgodzie przed jej wycofaniem. W celu wycofania zgody należy wysłać wiadomość e-mail na adres ';
export const POST_CONSENTS_INFO_AFTER_LINK = ' podając kanały komunikacji (w stosownych przypadkach).';

export const CONTACT_EMAIL = 'PolishCS@coopervision.co.uk';

export const CLOSE_TEXT = 'zwiń';

export const DATA_PROCESSING_INFO_TEXT = (isVisible) => `Dowiedz się, jak przetwarzamy Twoje dane${!isVisible ? '' : ` (${CLOSE_TEXT})`}`;

export const DATA_PROCESSING_INFO = [
    '- Administratorem Państwa danych osobowych jest spółka pod firmą CooperVision sp. z o.o. z siedzibą w Warszawie (kod pocztowy 02-697), ul. Domaniewska 44.',
    '- Informacje handlowe mogą dotyczyć produktów, usług, szkoleń lub innych działań promocyjnych. Informacje handlowe mogą być spersonalizowane w oparciu o profilowanie i wykorzystanie plików cookie, co umożliwia dostarczenie informacji najlepiej dopasowanych do Państwa preferencji oraz oczekiwań, biorąc pod uwagę Państwa wcześniejsze korzystanie z produktów, usług i stron internetowych CooperVision.',
    '- Przysługują Państwu określone prawa, w tym: (i) prawo do żądania dostępu do danych osobowych dotyczących Państwa, (ii) prawo do sprostowania tych danych, (iii) prawo do usunięcia tych danych, (iv) prawo do ograniczenia ich przetwarzania, (v) prawo do wniesienia sprzeciwu wobec przetwarzania danych osobowych dotyczących Państwaw celach marketingowych, w tym profilowania, (vi) prawo do przenoszenia tych danych – na zasadach określonych w Rozporządzeniu o Ochronie Danych Osobowych z dnia 27 kwietnia 2016 r. (“RODO”).',
    '- Dodatkowe informacje na temat zasad przetwarzania danych osobowych przez CooperVision Poland sp. o. o. jest dostępny w Polityce Prywatności'
];

export const INITIAL_CONSENT_TEXT = 'Przeczytałem poniższą informację i zgadzam się na otrzymywanie od CooperVision Poland sp. z o.o. informacji handlowych za pośrednictwem jednego lub kilku poniższych kanałów komunikacji:';

export const MISSING_REQUIRED_CONSENTS = 'Zaznacz wymagane zgody';
