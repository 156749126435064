import React from 'react';
import { Field } from 'formik';
import { isNil, prop } from 'ramda';
import Box from '../Box';
import CheckboxLabel from '../CheckboxLabel';
import ErrorMessage from '../ErrorMessage';
import CheckboxLabelWrapper from '../CheckboxLabelWrapper';

const CheckboxInput = ({
    checkbox, errors, touched
}) => (
    <Box marginTop="10px">
        <Field type="checkbox" name="consents" value={checkbox.id} id={checkbox.id} />
        <CheckboxLabelWrapper>
            <CheckboxLabel htmlFor={checkbox.id}>
                {/*  {checkbox.requiredTest(values) ? '* ' : ' '}
                {' '} */}
                {checkbox.label}
            </CheckboxLabel>
            {/*            {
                !isNil(checkbox.fullText) && (
                    <MoreInfoLink data-for={CHECKBOX_TOOLTIP_ID} data-tip={checkbox.fullText} data-event="click focus">czytaj więcej</MoreInfoLink>
                )
            } */}
            <ErrorMessage
                msg="Wymagana zgoda"
                isVisible={!isNil(checkbox.errorPropName) && prop(checkbox.errorPropName, errors) && prop('consents', touched)}
            />
        </CheckboxLabelWrapper>
    </Box>

);

export default CheckboxInput;
