import styled from 'styled-components';
import { theme } from 'styled-tools';

const TextInput = styled.input`
    width: calc(100% - (2 * ${theme('dims.textInput.paddingVertical')}));
    height: ${theme('dims.textInput.height')};
    
    border: 1px solid ${theme('colors.textInput.border')};
    
    border-radius: ${theme('dims.textInput.borderRadius')};
    padding-left: ${theme('dims.textInput.paddingVertical')};
    padding-right: ${theme('dims.textInput.paddingVertical')};
    
    margin-bottom: ${theme('dims.textInput.marginBottom')};

    :focus {
        outline: none;
        border: 1px solid ${theme('colors.brandBlue')};
    }
`;

export default TextInput;
