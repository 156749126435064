import React from 'react';
import { isNil } from 'ramda';
import * as PropTypes from 'prop-types';

import Box from '../Box';
import { StyledTextBox } from '../StyledTextBox/StyledTextBox';

export const TextBox = ({ text }) => isNil(text) ? null
    : (
        <Box>
            <StyledTextBox>
                {text}
            </StyledTextBox>
        </Box>
    );

TextBox.propTypes = {
    text: PropTypes.string
};

TextBox.defaultProps = {
    text: ''
};
