import styled from 'styled-components';
import { theme } from 'styled-tools';

const BackgroundOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.35);

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 100000;
`;

export default BackgroundOverlay;
