import styled from 'styled-components';
import { theme } from 'styled-tools';

export const StyledTextBox = styled.div`
    font-size: ${theme('fonts.form.formInfo.size')} !important;
    color: ${theme('colors.form.formInfo.text.color')} !important;
    margin-top: ${theme('dims.form.formInfo.marginTop')};
    margin-bottom: ${theme('dims.form.formInfo.marginBottom')};
    width: 100%;
    text-align: left !important;
`;
