export const theme = {
    colors: {
        brandBlue: '#5283bd',
        error: '#B00020',
        success: '#7aea73',
        textInput: {
            border: '#eee'
        },
        textInputLabel: {
            color: '#86a5a8'
        },
        title: {
            color: '#00419e'
        },
        subscribeButton: {
            color: 'white',
            backgroundColor: '#5393e7'
        },
        form: {
            formInfo: {
                text: {
                    color: '#252b2c'
                }
            },
            formLink: {
                text: {
                    color: '#5283bd'
                }
            }
        }
    },
    dims: {
        textMarginLeft: '20px',
        guideFormContainer: {
            width: '380px',
            padding: '20px'
        },
        textInput: {
            height: '40px',
            borderRadius: '80px',
            paddingVertical: '16px',
            marginBottom: '16px'
        },
        textInputLabel: {
            marginLeft: '20px'
        },
        subscribeButton: {
            height: '40px',
            borderRadius: '80px',
            marginVertical: '10px'
        },
        form: {
            formInfo: {
                marginTop: '10px',
                marginBottom: '10px'
            }
        },
        searchResults: {
            mediaQueries: {
                mobile: '700px'
            }
        }
    },
    fonts: {
        subscribeButton: {
            size: '16px'
        },
        checkboxLabel: {
            size: '12px'
        },
        title: {
            size: '26px'
        },
        form: {
            formInfo: {
                size: '11px'
            },
            formLink: {
                size: '11px'
            }
        }
    }
};
