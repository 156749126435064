import styled from 'styled-components';
import { defaultTo } from 'ramda';
import { prop, ifProp } from 'styled-tools';

const Box = styled.div`
  display: flex;
  flex-direction: ${ifProp('column', 'column', 'row')};
  margin-top: ${ifProp('marginTop', prop('marginTop'), ifProp('marginVertical', prop('marginVertical'), 0))};
  margin-bottom: ${ifProp('marginBottom', prop('marginBottom'), ifProp('marginVertical', prop('marginVertical'), 0))};margin-bottom: ${defaultTo(0, prop('marginVertical') || prop('marginBottom'))};
`;

export default Box;
