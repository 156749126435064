import styled from 'styled-components';
import { theme } from 'styled-tools';

const TextInputLabel = styled.div`
    flex: 1;
    margin-left: ${theme('dims.textInputLabel.marginLeft')};
    color: ${theme('colors.textInputLabel.color')};
    font-size: ${theme('fonts.textInputLabel.size')};
    margin-bottom: 4px;
`;

export default TextInputLabel;
