import styled from 'styled-components';
import { theme } from 'styled-tools';

const Title = styled.div`
    width: 100%;
    color: ${theme('colors.title.color')};
    font-size: ${theme('fonts.title.size')};
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 22px;
`;

export default Title;
