import styled from 'styled-components';
import { theme } from 'styled-tools';

const PrivacyLink = styled.a`
    font-size: 11px;
    color: ${theme('colors.brandBlue')};
    cursor: pointer;
    margin-top: 6px;
    margin-bottom: 6px;
    text-decoration: none;
    flex: 1;
    text-align: center;
`;

export default PrivacyLink;
