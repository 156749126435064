import React from 'react';

import { CONTACT_EMAIL, POST_CONSENTS_INFO_AFTER_LINK, POST_CONSENTS_INFO_PRE_LINK } from '../../constants';
import { StyledTextBox } from '../../../../components/StyledTextBox/StyledTextBox';

export const PostConsentsInfo = () => {
    const mailto = `mailto:${CONTACT_EMAIL}`;
    const link = <a href={mailto}>{CONTACT_EMAIL}</a>;
    return (
        <StyledTextBox>
            {POST_CONSENTS_INFO_PRE_LINK}
            {link}
            {POST_CONSENTS_INFO_AFTER_LINK}
        </StyledTextBox>
    );
};
